const portalRoutes = [
  {
    path: '/portal',
    name: 'portalList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'portalList',
      requireAuth: true,
      requireAccount: true,
      permissionsRequired: ['view_purchase'],
      title: 'Compras'
    },
    component: () => import('@/modules/portal/monitor/views/MonitorList')
  },
  {
    path: '/personalization',
    name: 'personalizationList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'portalList',
      requireAuth: true,
      requireAccount: true,
      permissionsRequired: ['view_purchase'],
      title: 'Compras'
    },
    component: () => import('@/modules/portal/personalization/views/MonitorList')
  }
]
export default portalRoutes