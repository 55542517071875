<template>
  <v-app-bar class="pt-1 app-bar-main pl-0" flat fixed app absolute v-if="$store.state.auth.account.id && $route.meta.displayOrganization" style="position: fixed !important; transition: none !important">
    <div class="d-flex ml-6" v-if="$route.name !== 'AccountDashboard'">
      <v-avatar class="mr-3 rounded-sm" tile color="blackBlue" size="16">
        <img :src="require(`@/assets/product-explorer/icons/${$store.getters['base/currentProduct']?.icon}--icon.svg`)" :width="16" />
      </v-avatar>
      <v-list-item-title class="text-h5 secondary--text font-weight-medium">{{$store.getters['base/currentProduct']?.name}}</v-list-item-title>
    </div>
    <!-- account menu -->
    <v-menu v-if="$store.state.auth.account.id && !$store.getters['base/currentProduct']?.dropdownModeTest && $route.name !== 'AccountDashboard'" close-on-click close-on-content-click offset-y :width="300" :nudge-bottom="8" left transition="slide-y-transition">
      <template v-slot:activator="{on, attrs}">
        <v-btn class="ml-5" v-on="on" v-bind="attrs" :ripple="false" outlined>
          <img class="ml-n1 mr-2" :src="require(`@/assets/account/mode-${isTestMode ? 'test' : 'prod'}--icon--active.svg`)" :width="isTestMode ? 16 : 10" />
          <img :src="require(`@/assets/account/account-choose-icon.svg`)" :width="16" />
          <span class="mx-2 d-inline-block text-truncate" style="max-width: 200px;">
            {{$store.state.auth.account.name | capitalize}}
          </span>
          <v-icon class="mr-n1" size="18">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <span class="ml-3 mb-2 d-block body-2 font-weight-semibold" style="max-width: 200px;">Organización</span>
        <v-list-item class="px-3" active-class="transparent" @click="testMode" :ripple="false">
          <v-list-item-title class="body-2">
            <v-row align="center" no-gutters>
              <img class="mr-3" :src="require(`@/assets/account/mode-${isTestMode ? 'test' : 'prod'}--icon.svg`)" :width="isTestMode ? 16 : 11" />
              Cambia a modo {{!isTestMode ? 'producción' : 'prueba'}}
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="px-3" v-if="$store.getters['base/currentProduct']?.configurations?.isActive" active-class="transparent" :to="{ name: $store.getters['base/currentProduct'].configurations?.routesList[0] ?? '' }" :ripple="false">
          <v-list-item-title class="body-2">
            <v-row align="center" no-gutters>
              <img class="mr-2" :src="require(`@/assets/account/account-setting-icon${$route.name === 'AccountBasic' ? '-active' : ''}.svg`)" :width="16" />Configuración de la organización
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="my-2"/>
        <span class="ml-3 mb-2 mt-5 d-block body-2 font-weight-semibold" style="max-width: 200px;">Organización</span>
        <v-card v-if="accountsList.length" class="overflow-y-auto pa-0 transparent mb-n5" id="scroll-target" flat max-height="120px">
          <v-card-text class="pa-0" :key="reset">
            <v-list-item class="px-3 py-0" v-for="(account, a) in accountsList" :key="a" active-class="transparent" @click="setAccount(account)" :ripple="false">
              <v-list-item-title class="body-2">
                <v-row align="center" no-gutters>
                  <img :src="require(`@/assets/account/account-choose-icon.svg`)" :width="16" />
                  <span class="mx-2 d-inline-block text-truncate" style="max-width: 190px;">{{ account.name }}</span>
                </v-row>
              </v-list-item-title>
            </v-list-item>
            <div v-if="lazyLoading" class="text-center mb-n12 mt-5">
              <v-progress-circular indeterminate color="primary" :width="2" :size="26" />
            </div>
          </v-card-text>
        </v-card>
        <v-card-text>
        </v-card-text>
      </v-list>
    </v-menu>
    <!-- end account menu -->

    <v-menu v-if="$store.state.auth.account.id && $store.getters['base/currentProduct']?.dropdownModeTest && $route.name !== 'AccountDashboard'" close-on-click close-on-content-click offset-y :width="300" :nudge-bottom="8" left transition="slide-y-transition">
      <template v-slot:activator="{on, attrs}">
        <v-btn class="ml-5" v-on="on" v-bind="attrs" :ripple="false" outlined>
          <v-row align="center" no-gutters>
            <img class="ml-n1 mr-2" :src="require(`@/assets/account/mode-${isTestMode ? 'test' : 'prod'}--icon--active.svg`)" :width="isTestMode ? 16 : 10" />            Modo {{isTestMode ? 'producción' : 'prueba'}}
          </v-row>
          <v-icon class="ml-5 mr-n1" size="18">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item class="px-3" @click="isTestMode = false" active-class="transparent" :ripple="false">
          <v-list-item-title class="body-2">
            <v-row align="center" no-gutters>
              <img class="mr-4" :src="require(`@/assets/account/mode-prod--icon.svg`)" :width="11" />
              Modo test
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="px-3" @click="isTestMode = true" active-class="transparent" :ripple="false">
          <v-list-item-title class="body-2">
            <v-row align="center" no-gutters>
              <img class="mr-3" :src="require(`@/assets/account/mode-test--icon.svg`)" :width="16" />
              Modo producción
            </v-row>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer />

    <!-- apps products -->
    <v-menu v-if="$store.state.auth.account.id && $route.name !== 'AccountDashboard'" close-on-click close-on-content-click offset-y width="300" :nudge-bottom="8" left transition="slide-y-transition">
      <template v-slot:activator="{on, attrs}">
        <v-btn class="mr-6" v-on="on" v-bind="attrs" outlined retain-focus-on-click :ripple="false">
        <v-icon left size="24">mdi-dots-grid</v-icon>
        <span class="ml-1 body-1 secondary--text">Explorador de productos</span>
        <v-icon right size="18">mdi-chevron-down</v-icon>
      </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item class="px-3 py-2" :class="{'disabled-list' : p.disabled}" v-for="p in products" :key="p.name" :ripple="false" @click.capture="setProduct(p)" :to="{ name: p.route, query: $route.query, params: $route.params }" :disabled="p.disabled">
            <v-list-item-icon class="mr-4 ma-0">
              <img class="pt-1" :src="require(`@/assets/product-explorer/${p.icon}--icon.svg`)" :width="32" />
            </v-list-item-icon>
            <v-list-item-content class="ma-0 pa-0">
              <v-list-item-title class="body-1 secondary--text font-weight-medium">{{p.name}}</v-list-item-title>
              <v-list-item-subtitle class="body-2 pt-0">{{p.description}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>
<script>
import SearchBar from '@/components/menu/SearchBar'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import accounts from '@/data/accountsList' // example
import productList from '@/data/productList' // product

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    SearchBar
  },
  data: () => ({
    products: productList,
    isActive: false,
    env: process.env?.VUE_APP_MODE ?? 'ax',
    isTestMode: false,
    accountsList: [],
    lazyLoading: false,
    scroll: 0,
    reset: 0,
    page: 1
  }),
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.isTestMode = this.$route.path.startsWith('/test')
      }
    }
  },
  created () {
    this.isTestMode = this.$route.path.startsWith('/test')
    this.accountsList = accounts
    const p = localStorage.getItem('current-product')
    this.$store.commit('base/SET_PRODUCT', p ?? 'insight')
  },
  methods: {
    setAccount (account = {}) {
      this.$store.dispatch('auth/SET_ACCOUNT', { account })
    },
    setProduct (product = '') {
      localStorage.setItem('current-product', product.id)
      this.$store.commit('base/SET_PRODUCT', product.id ?? 'insight')
    },
    deleteAuth () {
      this.$store.dispatch('auth/DELETE_AUTH')
      this.$store.commit('accounts/SET_ME', {})
    },
    testMode (e) {
      if (e && !this.$route.path.startsWith('/test')) {
        this.$router.replace({ path: `/test${this.$route.path}`, query: this.$route.query }).catch(err => err)
      } else {
        let currentParams = JSON.parse(JSON.stringify(this.$route.params))
        if (this.$route.params.prefix) delete currentParams.prefix
        this.$router.replace({name: `${this.$route.name}`, params: currentParams, query: this.$route.query}).catch(err => err)
      }
    }
  }
}
</script>