const authRoutes = [
  {
    path: '/account/setup',
    name: 'AuthSetup',
    meta: {
      displayNavigationDrawer: false,
      displayOrganization: false,
      parent: 'AccountBasic',
      requireAuth: true,
      showSupport: true,
      title: 'Setup'
    },
    component: () => import('@/modules/auth/views/Setup')
  },
  {
    path: '/auth/signin',
    name: 'AuthSignIn',
    meta: {
      displayNavigationDrawer: false,
      displayOrganization: true,
      title: 'Inicio de sesión'
    },
    component: () => import('@/modules/auth/views/SignIn')
  },
  {
    path: '/auth/signup',
    name: 'AuthSignUp',
    meta: {
      displayNavigationDrawer: false,
      displayOrganization: true,
      title: 'Registro de usuario'
    },
    component: () => import('@/modules/auth/views/SignUp')
  },
  {
    path: '/auth/settings',
    name: 'AuthSettings',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AuthSettings',
      requireAuth: true,
      title: 'Configuraciones de usuario'
    },
    component: () => import('@/modules/auth/views/Settings')
  },
  {
    path: '/auth/password/reset',
    name: 'AuthPasswordReset',
    meta: {
      displayNavigationDrawer: false,
      displayOrganization: true,
      title: 'Restablecimiento de la contraseña'
    },
    component: () => import('@/modules/auth/views/PasswordReset')
  },
  {
    path: '/auth/password/reset/confirm/:uid/:token',
    name: 'AuthPasswordResetConfirm',
    meta: {
      displayNavigationDrawer: false,
      displayOrganization: true,
      title: 'Confirmación restablecimiento de la contraseña'
    },
    component: () => import('@/modules/auth/views/PasswordResetConfirm')
  }
]
export default authRoutes