const purchaseRoutes = [
  {
    path: '/purchases/automation',
    name: 'AutomationList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AutomationList',
      requireAuth: true,
      requireAccount: true,
      title: 'Compras'
    },
    component: () => import('@/modules/purchase/automation/MonitorList')
  },
  {
    path: '/purchases/segments/:id',
    name: 'SegmentsRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SupplierListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Segmentos'
    },
    component: () => import('@/modules/purchase/segments/views/SegmentsRetrieveUpdate')
  },
  {
    path: '/purchases/segments',
    name: 'SegmentsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SupplierListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Segmentos'
    },
    component: () => import('@/modules/purchase/segments/MonitorList')
  },
  {
    path: '/purchases',
    name: 'PurchaseList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_purchase'],
      title: 'Compras'
    },
    component: () => import('@/modules/purchase/views/PurchaseList')
  },
  {
    path: '/purchases/:id',
    name: 'PurchaseRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información de documento'
    },
    component: () => import('@/modules/purchase/views/Main')
  },
  {
    path: '/:prefix(test)?/dashboard',
    name: 'PurchaseDashboard',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseDashboard',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_document', 'add_document'],
      use: ['ax'],
      title: 'Resumen'
    },
    component: () => import('@/modules/purchase/Dashboard')
  },
  {
    path: '/:prefix(test)?/claim',
    name: 'ClaimList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ClaimList',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_document', 'add_document'],
      use: ['ax'],
      title: 'Reclamación'
    },
    component: () => import('@/modules/purchase/claim/ClaimList')
  }
]
export default purchaseRoutes