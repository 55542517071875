const holdingRoutes = [
  {
    path: '/:prefix(test)?/holdings/organizations',
    name: 'OrganizationList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'OrganizationList',
      requireAuth: true,
      requireAccount: true,
      title: 'Organizaciones - Holdings'
    },
    component: () => import('@/modules/holding/organization/views/OrganizationList.vue')
  },
  {
    path: '/:prefix(test)?/holdings/organizations/:id',
    name: 'OrganizationRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'OrganizationList',
      requireAuth: true,
      requireAccount: true,
      title: 'Organización - Holdings'
    },
    component: () => import('@/modules/holding/organization/views/OrganizationRetrieveUpdate.vue')
  },
  {
    path: '/:prefix(test)?/holdings/orgunits',
    name: 'OrgunitList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'OrgunitList',
      requireAuth: true,
      requireAccount: true,
      title: 'Unidades de negocio - Holdings'
    },
    component: () => import('@/modules/holding/orgunit/views/OrgunitList.vue')
  },
  {
    path: '/:prefix(test)?/holdings/certificates',
    name: 'CertificateList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'CertificateList',
      requireAuth: true,
      requireAccount: true,
      title: 'Certificados - Holdings'
    },
    component: () => import('@/modules/holding/certificate/views/CertificateList.vue')
  },
  {
    path: '/:prefix(test)?/holdings/users',
    name: 'UserList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'UserList',
      requireAuth: true,
      requireAccount: true,
      title: 'Usuarios - Holdings'
    },
    component: () => import('@/modules/holding/user/views/UserList.vue')
  },
  {
    path: '/:prefix(test)?/holdings/users/create',
    name: 'UserCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'UserList',
      requireAuth: true,
      requireAccount: true,
      title: 'Usuarios - Holdings'
    },
    component: () => import('@/modules/holding/user/views/UserCreateUpdate.vue')
  },
  {
    path: '/:prefix(test)?/holdings/users/:id',
    name: 'UserUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'UserList',
      requireAuth: true,
      requireAccount: true,
      title: 'Usuarios - Holdings'
    },
    component: () => import('@/modules/holding/user/views/UserCreateUpdate.vue')
  },
  {
    path: '/audit',
    name: 'AuditList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AuditList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Auditoría'
    },
    component: () => import('@/modules/holding/audit/AuditList')
  }
]

export default holdingRoutes
