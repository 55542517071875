<template>
  <v-app class="background">
    <template v-if="!viewsWithVideo.includes($route.name)">
      <AppBarMain />
    </template>
    <div class="d-flex flex-column">
      <NavigationDrawer :key="$store.state.auth.account.country" v-if="$route.meta.displayNavigationDrawer"/>
      <v-row align="start" justify="center" no-gutters>
        <v-col cols="12" class="py-0" :style="$route.meta.displayNavigationDrawer && !['TemplateList', 'AuthChoose', 'WelcomeCompliance', 'MainCompliance'].includes($route.name) ? 'padding-left: 25px !important; padding-right: 25px !important': ''">
          <v-main class="ma-0">
            <v-container fluid :class="{'pa-0' : $route.name === 'AuthChoose'}">
              <!-- <AlertDialog v-if="dialog && $store.state.auth.account.country !== 'PE' && $route.name === 'AccountDashboard'" /> -->
              <router-view />
            </v-container>
          </v-main>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
import locale from '@/locale'
import generalsPE from '@/locale/es_PE'
import generalsCL from '@/locale/es_CL'
import AppBarMain from '@/components/menu/AppBar'
import AlertDialog from '@/components/commons/AlertDialog'
import NavigationDrawer from '@/components/menu/NavigationDrawer'

export default {
  components: {
    NavigationDrawer,
    AppBarMain,
    AlertDialog
  },
  data: () => ({
    dialog: false,
    viewsWithVideo: ['Index', 'AuthSignIn', 'AuthSetup', 'AuthChoose', 'AuthSignUp', 'AuthPasswordReset', 'AuthPasswordResetConfirm']
  }),
  watch: {
    $route (to, from) {
      if (to.path !== from.path) {
        this.$vuetify.goTo(0, { duration: '0', easing: 'linear' })
      }
      if (((from.name === 'AuthChoose' || !from.name) && to.name === 'AccountDashboard')) this.dialog = true
      else this.dialog = false
    },
    '$store.state.auth.account.country': {
      handler () {
        this.setMessagesI18n()
      },
      inmediate: true,
      deep: true
    }
  },
  created () {
    this.setMessagesI18n()
    this.$vuetify.theme.dark = localStorage.getItem('is-active-dark-mode-ax') ? !localStorage.getItem('is-active-dark-mode-ax')?.includes('false') : true
  },
  methods: {
    setMessagesI18n () {
      let currentLocale = {}
      let messages = (this.$store?.state?.auth?.account?.country ?? JSON.parse(localStorage.getItem('vuex'))?.auth?.account?.country) === 'PE' ? generalsPE : generalsCL
      currentLocale.es = {...messages, ...locale.es}
      this.$i18n._vm.messages = {...currentLocale}
    }
  }
}
</script>