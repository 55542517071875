export default {
  /**
 * Main menu options
 * @product INSIGHT
 */
insight: {
  list: []
},
/**
 * Main menu options
 * @product OUTBOUND
 */
outbound: {
  list: [
    {
      title: 'Inicio',
      icon: 'home',
      link: 'PanelOutbound',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Email tracker',
      icon: 'tracker',
      link: 'TrackerMonitor',
      show: true,
      use: ['CL', 'PE']
    },
    {
      title: 'Plantillas',
      icon: 'template',
      link: 'TemplateMonitor',
      show: true,
      use: ['CL', 'PE'],
      options: [
        {
          title: 'Monitor',
          link: 'TemplateMonitor',
          associatedRoutes: [],
          use: ['CL']
        },
        {
          title: 'Plantillas',
          link: 'TemplateList',
          associatedRoutes: [],
          use: ['CL']
        }
      ]
    },
    {
      title: 'Portal del consumidor',
      icon: 'portal',
      link: 'PortalList',
      show: true,
      use: ['CL', 'PE']
    },
    {
      title: 'Reportes',
      icon: 'report',
      link: 'SentDocumentReportList',
      show: true,
      use: ['CL', 'PE'],
      options: [
        {
          title: 'Envío de documentos',
          link: 'SentDocumentReportList',
          associatedRoutes: [],
          use: ['CL', 'PE']
        }
      ]
    }
  ]
},
  /**
 * Main menu options
 * @product FOLIOS
 */
folios: {
  list: [
    {
      title: 'Monitor',
      icon: 'monitor',
      link: 'FoliosMonitor',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Folios',
      icon: 'folios',
      link: 'FoliosListCreate',
      show: true,
      use: ['CL', 'PE']
    }
  ]
},

/**
 * Main menu options
 * @product POS
 */
pos: {
  list: [
    {
      title: 'Monitor',
      icon: 'monitor',
      link: 'MonitorList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Tiendas',
      icon: 'branch',
      link: 'BranchListCreate',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Puntos de ventas',
      icon: 'pos',
      link: 'PosListCreate',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    // {
    //   title: 'Rollout',
    //   icon: 'rollout',
    //   link: 'RolloutList',
    //   show: true,
    //   use: ['CL', 'PE'],
    //   options: []
    // },
    {
      title: 'Logs',
      icon: 'logs',
      link: 'LogsList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Grupos',
      icon: 'groups',
      link: 'PosGroupsList',
      show: true,
      use: ['CL', 'PE'],
      options: [
        {
          title: 'Todos los Grupos',
          link: 'PosGroupsList',
          associatedRoutes: [],
          use: ['CL', 'PE']
        },
        {
          title: 'Farmacias - zona sur',
          link: 'PosGroupsRetrieveUpdate',
          directAccess: true,
          params: 'pos_qsgVnf8ixhJItmv3Zw',
          associatedRoutes: [],
          use: ['CL', 'PE']
        }
        // {
        //   title: 'Grupo 2',
        //   link: 'PosGroupsRetrieveUpdate',
        //   params: 'pos_tqvZFBPwq86mbfIg2V',
        //   associatedRoutes: [],
        //   directAccess: true,
        //   use: ['CL', 'PE']
        // }
      ]
    },
    {
      title: 'Reportes',
      icon: 'report',
      link: 'BranchReportList',
      show: true,
      use: ['CL', 'PE'],
      options: [
        {
          title: 'Reporte de tiendas',
          link: 'BranchReportList',
          associatedRoutes: [],
          use: ['PE']
        },
        {
          title: 'Reporte de POS',
          link: 'PosBranchReportList',
          associatedRoutes: [],
          use: ['PE'],
          showSupport: true
        },
        {
          title: 'Reporte de folios',
          link: 'FoliosReportList',
          associatedRoutes: [],
          use: ['PE'],
          showSupport: true
        }
      ]
    }
  ]
},

/**
 * Main menu options
 * @product purchases
 */
purchase: {
  list: [
    {
      title: 'Resumen',
      icon: 'dashboard',
      link: 'PurchaseDashboard',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Gestión documental',
      icon: 'purchase',
      link: 'PurchaseList',
      show: true,
      use: ['CL', 'PE'],
      associatedRoutes: ['PurchaseRetrieve'],
      options: []
    },
    {
      title: 'Reclamación',
      icon: 'claim',
      link: 'ClaimList',
      show: true,
      use: ['CL', 'PE'],
      associatedRoutes: ['SupplierRetrieveUpdate'],
      options: []
    },
    {
      title: 'Automatización',
      icon: 'automation',
      link: 'AutomationList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Proveedores',
      icon: 'customer',
      link: 'SupplierListCreate',
      show: true,
      use: ['CL', 'PE'],
      associatedRoutes: ['SupplierRetrieveUpdate'],
      options: [
        {
          title: 'Proveedores',
          link: 'SupplierListCreate',
          associatedRoutes: ['SupplierRetrieveUpdate'],
          use: ['CL']
        },
        {
          title: 'Segmentos',
          link: 'SegmentsList',
          associatedRoutes: ['PurchaseRetrieve'],
          use: ['CL']
        }
      ]
    }

    // {
    //   title: 'Proveedores',
    //   link: 'SupplierListCreate',
    //   associatedRoutes: ['SupplierRetrieveUpdate'],
    //   use: ['CL']
    // },
    // {
    //   title: 'Segmentos',
    //   link: 'SegmentsList',
    //   associatedRoutes: ['PurchaseRetrieve'],
    //   use: ['CL']
    //   // options: [
    //   //   {
    //   //     title: 'Todos los Grupos',
    //   //     link: 'PosGroupsList',
    //   //     associatedRoutes: [],
    //   //     use: ['CL', 'PE']
    //   //   },
    //   //   {
    //   //     title: 'Proveedores RM',
    //   //     link: 'PosGroupsRetrieveUpdate',
    //   //     directAccess: true,
    //   //     params: 'pos_qsgVnf8ixhJItmv3Zw',
    //   //     associatedRoutes: [],
    //   //     use: ['CL', 'PE']
    //   //   },
    //   //   {
    //   //     title: 'Proveedores Regiones',
    //   //     link: 'PosGroupsRetrieveUpdate',
    //   //     params: 'pos_tqvZFBPwq86mbfIg2V',
    //   //     associatedRoutes: [],
    //   //     directAccess: true,
    //   //     use: ['CL', 'PE']
    //   //   }
    //   // ]
    //   }
    // {
    //   title: 'Reportes',
    //   icon: 'report',
    //   link: 'DocumentSummary',
    //   show: true,
    //   use: ['CL', 'PE'],
    //   options: [
    //     // {
    //     //   title: 'Envío de documentos',
    //     //   link: 'SentDocumentList',
    //     //   associatedRoutes: [],
    //     //   use: ['CL', 'PE']
    //     // },
    //     {
    //       title: 'Registro de ventas',
    //       link: 'DocumentSummary',
    //       associatedRoutes: [],
    //       use: ['CL'],
    //       showSupport: true
    //     }
    //   ]
    // }
  ]
},

/**
 * Main menu options
 * @product documents
 */
documents: {
  list: [
    {
      title: 'Resumen',
      icon: 'dashboard',
      link: 'DocumentsDashboard',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Gestión documental',
      icon: 'document',
      link: 'DocumentsList',
      show: true,
      use: ['CL', 'PE'],
      associatedRoutes: ['DocumentsCreate', 'DocumentsRetrieve'],
      options: []
    },
    {
      title: 'Receptores',
      icon: 'customer',
      link: 'CustomerListCreate',
      show: true,
      use: ['CL', 'PE'],
      options: [],
      associatedRoutes: ['CustomerRetrieveUpdate']
    }
    // {
    //   title: 'Emisión por lotes',
    //   icon: 'fl',
    //   link: 'ProcessList',
    //   show: true,
    //   use: ['CL', 'PE'],
    //   options: [
    //     {
    //       title: 'Monitor',
    //       link: 'ProcessList',
    //       associatedRoutes: ['DocumentsCreate', 'DocumentsRetrieve'],
    //       use: ['CL', 'PE']
    //     },
    //     {
    //       title: 'Procesos',
    //       link: 'DocumentsBatchListCreate',
    //       associatedRoutes: ['DocumentsBatchRetrieve'],
    //       use: ['CL', 'PE']
    //     }
    //     // {
    //     //   title: 'Clientes',
    //     //   link: 'CustomerListCreate',
    //     //   associatedRoutes: ['CustomerRetrieveUpdate'],
    //     //   use: ['CL']
    //     // }
    //   ]
    // },
    // {
    //   title: 'Reportes',
    //   icon: 'report',
    //   link: 'DocumentSummary',
    //   show: true,
    //   use: ['CL', 'PE'],
    //   options: [
    //     // {
    //     //   title: 'Envío de documentos',
    //     //   link: 'SentDocumentList',
    //     //   associatedRoutes: [],
    //     //   use: ['CL', 'PE']
    //     // },
    //     {
    //       title: 'Registro de ventas',
    //       link: 'DocumentSummary',
    //       associatedRoutes: [],
    //       use: ['CL'],
    //       showSupport: true
    //     }
    //   ]
    // }
  ]
},

compliance: {
  list: [
    {
      title: 'Panel de operación',
      icon: 'operation',
      link: 'ComplianceOperation',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Task manager',
      icon: 'task-manager',
      link: 'ComplianceTask',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Cuadraturas',
      icon: 'process',
      link: 'ComplianceProcessList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'SIRE',
      icon: 'sire',
      link: 'ComplianceSireList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    }
  ]
},

holdings: {
  list: [
    {
      title: 'Organizaciones',
      icon: 'organizations',
      link: 'OrganizationList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Unidades de negocio',
      icon: 'orgunits',
      link: 'OrgunitList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Certificados',
      icon: 'certificates',
      link: 'CertificateList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Usuarios',
      icon: 'users',
      link: 'UserList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    },
    {
      title: 'Auditoría',
      icon: 'audit',
      link: 'AuditList',
      show: true,
      use: ['CL', 'PE'],
      options: []
    }
  ]
}
}