<template>
  <v-row no-gutters>
    <v-navigation-drawer
      :mini-variant="mini"
      permanent
      app
      stateless
      fixed
      hide-overlays
      :width="215"
    >
    <v-list class="px-0 py-5">
      <v-list-item class="px-4 py-0">
        <img class="ml-n1" :src="require(`@/assets/appAsset-brand--axteroid-icon.svg`)" />
      </v-list-item>
      <v-divider class="mt-3 mx-3" />
      <v-list-item class="px-4 py-1">
        <v-btn class="ml-n4" small text @click.stop="mini=!mini" :ripple="false">
          <img :src="require(`@/assets/navigationdrawer-ax/${mini ? 'open' : 'close'}-icon.svg`)" :height="17" />
        </v-btn>
      </v-list-item>
      <template v-if="!!$store.state.auth.account.id">
        <v-divider class="mt-0 mx-3 mb-2" />
        <v-list-item v-for="link in list" class="px-3" :to="{ name: link.link }" active-class="transparent" exact :key="link.title" link :ripple="false">
          <v-list-group class="pb-2 mt-n1" v-if="(((link || {}).options || []).length)" :value="!mini && selectedIcon === link.link ? link.show : false" active-class="transparent secondary--text" exact :ripple="false" :key="mini" :no-action="!!mini">
            <template v-slot:activator>
              <v-list-item-icon class="mr-0" style="width: 30px;">
                <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="20" :nudge-top="23" offset-x :key="mini">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-row align="center" no-gutters>
                        <v-btn text class="pa-0" v-bind="attrs" v-on="mini ? on : false" style="z-index: 100 !important; position: absolute;">
                          <img class="mx-1 icon-panel" :src="require(`@/assets/navigationdrawer-ax/product-${$store.state.base.currentProduct}/${link.icon}-icon${selectedIcon === link.link || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" />
                          <v-icon class="btn-tooltip-panel" :color="hover ? 'primary' : 'defaultGrey'" v-if="mini" size="16">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-row>
                    </v-hover>
                  </template>
                  <v-list class="pa-0">
                    <span class="px-3 mb-2 d-block secondary--text subtitle-2 font-weight-medium">{{link.title}}</span>
                    <v-list-item
                      class="px-3 mb-n1 font-weight-medium text--secondary"
                      active-class="transparent"
                      :ripple="false" v-for="(option, i) in link.options"
                      :key="`${i}-${option.link}`"
                      :to="option.directAccess ? { name: option.link, params: {...currentParams, id: option.params }} : { name: option.link, params: currentParams }"
                      exact
                    >
                      <v-list-item-title class="body-1">{{ option.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-icon>
              <v-list-item-title class="d-inline-block subtitle-2 text-truncate pl-4" :class="selectedIcon === link.link ? 'primary--text font-weight-medium' : 'secondary--text font-weight-medium'" style="width: 118px; line-height: 1.6;">
                <template>{{link.title}}</template>
              </v-list-item-title>
            </template>
            <v-list-item
              class="pl-3 py-0"
              :class="$route.name === option.link || option.associatedRoutes.includes($route.name) ? 'primary--text font-weight-medium' : 'secondary--text font-weight-medium'"
              active-class="transparent"
              :ripple="false" v-for="(option, i) in link.options"
              :key="`${i}-${option.link}`"
              :to="option.directAccess ? { name: option.link, params: {...currentParams, id: option.params }} : { name: option.link, params: currentParams }"
              exact
            >
              <v-divider vertical />
              <v-list-item-title :class="['body-1 pl-3', i === 0 ? 'pt-1' : 'pt-2', i === (link.options.length - 1) ? 'mb-2' : '' ]">{{option.title}} </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <template v-else>
            <v-list-item class="pl-0 py-0 mt-1" :to="{ name: link.link, params: currentParams }" exact :ripple="false" active-class="transparent font-weight-medium" style="height: 30px">
              <v-list-item-icon class="mr-4 ma-0 justify-center">
                <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="28" :nudge-top="15" offset-x :key="mini">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <img class="ml-1" v-bind="attrs" v-on="mini ? on : false" :src="require(`@/assets/navigationdrawer-ax/product-${$store.state.base.currentProduct}/${link.icon}-icon${selectedIcon === link.link || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="18" />
                    </v-hover>
                  </template>
                  <v-list class="pa-0">
                    <span class="px-3 d-block secondary--text subtitle-2 font-weight-medium">{{link.title}}</span>
                  </v-list>
                </v-menu>
              </v-list-item-icon>
              <v-list-item-title class="d-inline-block subtitle-2 text-truncate" :class="selectedIcon === link.link ? 'primary--text font-weight-medium' : 'secondary--text font-weight-medium'" style="line-height: 1.5; margin-top:-13px">{{link.title}}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item>
      </template>
    </v-list>

      <!-- settings -->
      <template v-slot:append>
        <v-list dense class="px-4 pt-2 pb-1">
          <template v-if="$store.getters['base/currentProduct']?.configurations?.isActive">
            <v-list-item :to="{ name: $store.getters['base/currentProduct']?.configurations?.routesList[0] ?? '' }" class="mb-n1" :ripple="false" active-class="transparent">
              <v-list-item-icon>
                <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="28" :nudge-top="15" offset-x :key="mini">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <span class="d-flex">
                        <img class="mx-1" v-bind="attrs" v-on="mini ? on : false" :src="require(`@/assets/navigationdrawer-ax/account-setting-icon${hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="20" />
                      </span>
                    </v-hover>
                  </template>
                  <v-list class="pa-0">
                    <span class="px-3 d-block secondary--text subtitle-2 font-weight-medium">Configuración</span>
                  </v-list>
                </v-menu>
              </v-list-item-icon>
              <v-list-item-title class="ml-n5">
                <template>
                  <span class="d-inline-block secondary--text subtitle-2 font-weight-medium text-truncate">Configuración</span>
                </template>
              </v-list-item-title>
            </v-list-item>
            <v-divider />
          </template>
          <v-list-item :href="`https://docs.axteroid.com/`" target="_blank" class="mb-4 mt-2" :ripple="false" active-class="transparent">
            <v-list-item-icon>
              <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="28" :nudge-top="15" offset-x :key="mini">
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <span class="d-flex">
                      <img class="mx-1" v-bind="attrs" v-on="mini ? on : false" :src="require(`@/assets/navigationdrawer-ax/help-icon${hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="18" />
                    </span>
                  </v-hover>
                </template>
                <v-list class="pa-0">
                  <span class="px-3 d-block secondary--text subtitle-2 font-weight-medium">Centro de ayuda</span>
                </v-list>
              </v-menu>
            </v-list-item-icon>
            <v-list-item-title class="ml-n5">
              <template>
                <span class="d-inline-block secondary--text subtitle-2 font-weight-medium text-truncate">Centro de ayuda</span>
              </template>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!!$store.state.auth.account.id" :to="{ name: 'DownloadCenterList', params: currentParams }"  :ripple="false" active-class="transparent" class="mt-n9">
            <v-list-item-icon>
              <v-list-item :href="`https://docs.axteroid.com/`" target="_blank" class="mb-4 mt-2" :ripple="false" active-class="transparent">
                <v-list-item-icon>
                  <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="28" :nudge-top="15" offset-x :key="mini">
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <span class="d-flex">
                          <img class="mx-1" v-bind="attrs" v-on="mini ? on : false" :src="require(`@/assets/navigationdrawer-ax/download-icon${selectedIcon === 'DownloadCenterList' || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="18" />
                        </span>
                      </v-hover>
                    </template>
                    <v-list class="pa-0">
                      <span class="px-3 d-block secondary--text subtitle-2 font-weight-medium">Centro de descargas</span>
                    </v-list>
                  </v-menu>
                </v-list-item-icon>
                <v-list-item-title class="ml-n5">
                  <template>
                    <span class="d-inline-block secondary--text subtitle-2 font-weight-medium text-truncate">Centro de descargas</span>
                  </template>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item @click="saveDarkMode()" :ripple="false" active-class="transparent" :class="!!$store.state.auth.account.id ? 'mt-3' : 'mt-n6'">
            <v-list-item-icon>
              <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="28" :nudge-top="15" offset-x :key="mini">
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <span class="d-flex">
                      <img class="mx-1" v-bind="attrs" v-on="mini ? on : false" :src="require(`@/assets/navigationdrawer-ax/dark-mode-icon${hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="18" />
                    </span>
                  </v-hover>
                </template>
                <v-list class="pa-0">
                  <span class="px-3 d-block secondary--text subtitle-2 font-weight-medium">Modo {{ !$vuetify.theme.dark ? 'oscuro' : 'claro' }}</span>
                </v-list>
              </v-menu>
            </v-list-item-icon>
            <v-list-item-title class="ml-n5 subtitle-2 secondary--text font-weight-medium">Modo {{ !$vuetify.theme.dark ? 'oscuro' : 'claro' }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider class="mx-2 mb-1 mt-n2" />
        <v-list dense class="px-4 pt-0 mb-2">
          <v-list-item :ripple="false" active-class="transparent" class="mt-1">
            <v-list-group :value="!mini && selectedIcon === 'AuthSettings' ? true : false" active-class="transparent secondary--text" exact :ripple="false" :key="mini" :no-action="!!mini">
              <template v-slot:activator>
                <v-list-item-icon class="mr-0" style="width: 30px;">
                  <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="20" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-row align="center" no-gutters>
                          <img class="ml-1 icon-panel" v-bind="attrs" v-on="on" :src="require(`@/assets/navigationdrawer-ax/account-icon${selectedIcon === 'AuthSettings' || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="16" />
                          <v-btn text class="ml-5 pa-0" v-bind="attrs" v-on="on" style="z-index: 100 !important; position: absolute; ">
                            <v-icon class="ml-1 btn-tooltip-panel" :color="hover ? 'primary' : 'defaultGrey'" v-if="mini" size="16">mdi-dots-vertical</v-icon>
                          </v-btn>
                        </v-row>
                      </v-hover>
                    </template>
                    <v-list class="pa-0">
                      <span class="px-3 mb-2 d-block secondary--text subtitle-2 font-weight-medium">Configuración de la cuenta</span>
                      <v-list-item class="px-3 mb-n1" active-class="transparent font-weight-black" :class="selectedIcon === 'AuthSettings' ? 'primary--text font-weight-semibold' : 'secondary--text font-weight-medium'" :to="{ name: 'AuthSettings' }" :ripple="false" exact>
                        <v-list-item-title class="body-1 font-weight-medium text--secondary">Configuración de la cuenta</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="px-3" @click="deleteAuth" active-class="transparent font-weight-black" :ripple="false" exact>
                        <v-list-item-title class="body-1 font-weight-medium text--secondary">Cerrar sesión</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-icon>
                <v-list-item-title class="pl-3 mr-n4" style="line-height: 3.5;">
                  <template>
                    <span class="d-inline-block subtitle-2 text-truncate" :class="selectedIcon === 'AuthSettings' ? 'primary--text font-weight-semibold' : 'secondary--text font-weight-medium'" style="max-width: 120px;">{{ $store.state.auth.userAccount.first_name || 'Sin definir' }} {{$store.state.auth.userAccount.last_name}}</span>
                    <span class="mt-n4 d-block body-2 text-truncate" :class="selectedIcon === 'AuthSettings' ? 'primary--text ' : 'secondary--text'" style="max-width: 120px;">{{ $store.state.auth.userAccount.email || 'Sin definir' }}</span>
                  </template>
                </v-list-item-title>
              </template>
              <v-list-item class="py-0 pl-2 mt-4" active-class="transparent font-weight-black" :class="selectedIcon === 'AuthSettings' ? 'primary--text font-weight-semibold' : 'secondary--text font-weight-medium'" :to="{ name: 'AuthSettings', params: currentParams }" :ripple="false" exact>
                <v-divider vertical />
                <v-list-item-title class="body-1 pl-3 pt-1`">Configuración de la cuenta</v-list-item-title>
              </v-list-item>
              <v-list-item class="py-0 pl-2 secondary--text" @click="deleteAuth" active-class="transparent font-weight-black" :ripple="false" exact>
                <v-divider vertical />
                <v-list-item-title class="`body-1 pl-3 pt-2`">Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-row>
</template>
<script>
import routesList from '@/collections/itemsNavigationDrawer'

export default {
  data: () => ({
    drawer: true,
    menu: false,
    mini: true,
    list: [],
    resetDrawer: 0,
    selectedIcon: 'BranchListCreate',
    itemList: []
  }),
  computed: {
    currentParams () {
      let params = JSON.parse(JSON.stringify(this.$route.params))
      delete params.id
      return params
    }
  },
  watch: {
    mini (val) {
      this.$store.commit('base/EXPAND_NAVIGATION_DRAWER', !val)
      // this.resetDrawer += 1
    },

    $route: {
      immediate: false,
      handler: function (val) {
        this.selectedIcon = val.meta.parent
        // this.resetDrawer += 1
      }
    },
    '$store.state.base.currentProduct': {
      handler (val) {
        this.itemList = JSON.parse(JSON.stringify(routesList[val].list))
        this.setDrawerItems()
      },
      inmediate: true,
      deep: true
    },
    '$store.state.auth.account.country': {
      handler () {
        this.setDrawerItems()
      },
      inmediate: true,
      deep: true
    }
  },
  mounted () {
    this.selectedIcon = this.$route.meta.parent
  },
  created () {
    this.itemList = JSON.parse(JSON.stringify(routesList[this.$store.state?.base?.currentProduct]?.list ?? []))
    this.setDrawerItems()
  },
  methods: {
    deleteAuth () {
      localStorage.setItem('current-product', 'insight')
      this.$store.dispatch('auth/DELETE_AUTH')
      this.$store.commit('accounts/SET_ME', {})
    },
    saveDarkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('is-active-dark-mode-ax', this.$vuetify.theme.dark)
    },
    setDrawerItems () {
      this.list = this.itemList.filter(item => item.use.includes(this.$store.state.auth.account.country ?? 'PE'))
    }
  }
}
</script>