const supplierRoutes = [
  {
    path: '/:prefix(test)?/suppliers/:id',
    name: 'SupplierRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SupplierListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Detalles y actualización de proveedores'
    },
    component: () => import('@/modules/supplier/views/Main')

  },
  {
    path: '/:prefix(test)?/suppliers',
    name: 'SupplierListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SupplierListCreate',
      requireAuth: true,
      requireAccount: true,
      title: 'Proveedores'
    },
    component: () => import('@/modules/supplier/views/SupplierListCreate')
  }
]
export default supplierRoutes