export default [
    {
        id: "acc_yNFXfO1pYWBjDllw6E",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Av. Defensores del Morro Nro. 1277 (.)",
        name: "BOTICAS IP S.A.C.",
        brand: 'brand--inkafarma.png',
        tax_id: "20608430301",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "blopez@farmaciasperuanas.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-16T17:32:20.704984-03:00",
        updated: "2023-10-16T17:32:53.659222-03:00",
        country: "PE",
        state: "PE150108"
    },
    {
        id: "acc_vfLQZSSAj4GHbApBAK",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: "mem_3VPjItnQtsWlQbU1WY",
        address: "Av. Elias Aguirre Nro. 1107 (con 9 de Diciembre) Loreto",
        name: "Jorsa de la Selva S.A.C",
        brand: 'brand--dojorsa.png',
        tax_id: "20531502508",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "blopez@farmaciasperuanas.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-16T17:22:49.666326-03:00",
        updated: "2023-10-16T17:22:49.666347-03:00",
        country: "PE",
        state: "PE160112"
    },
    {
        id: "acc_IfyWdJl9xKP6KyPHDy",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Cal. Victor Alzamora Nro. 147",
        brand: 'brand--mifarma.png',
        name: "Mifarma S.A.C",
        tax_id: "20512002090",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "blopez@farmaciasperuanas.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-16T17:10:13.121035-03:00",
        updated: "2023-11-30T11:33:52.807576-03:00",
        country: "PE",
        state: "PE140106"
    },
    {
        id: "acc_xQD7KHsdc4GJZddPuj",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Cal. Victor Alzamora Nro. 147",
        name: "Farmacias Peruanas S.A.C.",
        brand: 'brand--farmacias-peruanas.png',
        tax_id: "20605900012",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "blopez@farmaciasperuanas.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-16T17:51:01.587873-03:00",
        updated: "2023-10-16T17:51:16.285423-03:00",
        country: "PE",
        state: "PE140106"
    },
    {
        id: "acc_2rgvQmtvXF1Sy9h2Wu",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Av. Defensores del Morro Nro. 1277 (Ex Fabrica Luchetti)",
        name: "Inretail Pharma S.A.",
        tax_id: "20331066703",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "fgonzales@farmaciasperuanas.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-16T17:43:17.815182-03:00",
        updated: "2023-11-27T18:31:11.563837-03:00",
        country: "PE",
        state: "PE150108"
    },
    {
        id: "acc_L7LUd6uSGglzJSW168",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "San Borja",
        name: "COMPAÑÍA FOOD RETAIL S.A.C",
        brand: 'brand--vivanda.png',
        tax_id: "20608300393",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:38:37.481902-03:00",
        updated: "2023-10-18T14:41:27.478117-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_ULle0YyL621UKMkb1P",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: "mem_y5ebTCZrbfxUYpwikK",
        address: "San Borja",
        name: "COMPAÑÍA HARD DISCOUNT S.A.C.",
        brand: 'brand--mass.png',
        tax_id: "20608280333",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:38:08.922196-03:00",
        updated: "2023-09-26T17:38:08.922220-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_dvA5wshESXdPRJAVD1",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "San Borja",
        name: "MAKRO SUPERMAYORISTA S.A.",
        tax_id: "20492092313",
        color_asset: "#0583AE",
        brand: 'brand--makro.png',
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:36:27.422513-03:00",
        updated: "2023-09-26T17:36:27.422553-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_QnuP1IN6MfG11j1lHw",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "San Borja",
        name: "SHERFARMA S.A.C",
        tax_id: "20376365680",
        brand: 'brand--sherfarma.png',
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:37:03.658409-03:00",
        updated: "2023-09-26T17:37:03.658434-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_DoNRsoG1aqoh4f3yOz",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: "mem_gxVs42jnWsyRmqw2xs",
        address: "San Borja",
        name: "Supermercados Peruanos S.A",
        brand: 'brand--supermercados-peruanos.png',
        tax_id: "20100070970",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:35:45.486322-03:00",
        updated: "2023-11-30T17:24:15.507583-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_M6vGOaa3kKTPMTuUyE",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Av. Centenario Mza. 365 Lote. 16 (C.C Real Plaza Pucallpa) Yarinacocha Provincia: Coronel Portillo Departamento: Ucayali, Perú",
        name: "Homecenters Peruanos Oriente S.A.C",
        brand: 'brand--promart.png',
        tax_id: "20394077101",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "ronny.melendez@promart.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-21T14:44:47.923971-03:00",
        updated: "2023-09-21T14:44:47.923996-03:00",
        country: "PE",
        state: "PE250105"
    },
    {
        id: "acc_lcMhLHR8g9W5YBNwPO",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Av. Aviacion Nro. 2405 (Piso 5)",
        name: "Homecenters Peruanos S.A.",
        brand: 'brand--promart.png',
        tax_id: "20536557858",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "ronny.melendez@promart.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-21T18:13:05.702024-03:00",
        updated: "2023-09-21T18:13:05.702048-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_Aj4YUtniKrhriyXSBx",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: "mem_cUhMOkqNZrtVzd7aXM",
        address: "Av. Centenario Nro. 1642 C.C. Real Plaza (Cruce con Jr. Eglington y Centenario)",
        name: "Tiendas Peruanas del Oriente S.A.C.",
        brand: 'brand--oeshle.png',
        tax_id: "20600414276",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "ronny.melendez@promart.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-21T18:17:07.525366-03:00",
        updated: "2023-09-21T18:17:07.525390-03:00",
        country: "PE",
        state: "PE250105"
    },
    {
        id: "acc_cAWT54NXRflhDxgNBB",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: "mem_khLa4AlTkStMbJLwB6",
        address: "Av. Aviacion Nro. 2405",
        brand: 'brand--oeshle.png',
        name: "Tiendas Peruanas S.A.",
        tax_id: "20493020618",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "ronny.melendez@promart.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-21T18:04:07.927784-03:00",
        updated: "2023-09-21T18:04:07.927808-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_fvaJv0j2s2wWuiwYGz",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "La Victoria",
        name: "VANTTIVE S.A.C",
        tax_id: "20547141068",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "pvela@quimicasuiza.com",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-05T17:30:13.355376-03:00",
        updated: "2023-10-05T17:30:13.355400-03:00",
        country: "PE",
        state: "PE140106"
    },
    {
        id: "acc_Ezn2nHE8kmz460cARx",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: "mem_cr3YrVSk89iOCmt1EJ",
        address: "Av. Aviacion Nro. 2405 (Piso 9 - Edificio San Sebastian)",
        name: "Financiera Oh",
        tax_id: "20522291201",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "hugo.silva@somosoh.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-24T13:42:36.627404-03:00",
        updated: "2023-11-24T13:42:36.627437-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_HLrI9uyOKyzP2iVpu7",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: "mem_9kuRDJbNky4iIStEFT",
        address: "Av. Punta del Este Nro. 2403 (Puerta 4 - Piso 2)",
        name: "Real Plaza S.R.L.",
        brand: 'brand--real-plaza.png',
        tax_id: "20511315922",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "lizbeth.tapia@realplaza.com.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-12T11:49:14.022275-03:00",
        updated: "2023-10-12T11:49:14.022298-03:00",
        country: "PE",
        state: "PE150113"
    },
    {
        id: "acc_N2sOfTsKeOlyHQnyTo",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Av. Punta del Este Nro. 2403",
        name: "INMOBILIARIA PUERTA DEL SOL S.A.C.",
        tax_id: "20478058137",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "lizbeth.tapia@realplaza.com.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-30T19:45:25.144644-03:00",
        updated: "2023-11-30T19:45:41.839813-03:00",
        country: "PE",
        state: "PE150113"
    },
    {
        id: "acc_eMzYhaabgmjjjB2ow2",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Av. Punta del Este Nro. 2403",
        name: "PATRIMONIO EN FIDEICOMISO D.S N°093-2002-EF INTERPROPERTIES PERU",
        tax_id: "20519398169",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "lizbeth.tapia@realplaza.com.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-30T19:41:32.394571-03:00",
        updated: "2023-11-30T19:41:45.720160-03:00",
        country: "PE",
        state: "PE150113"
    },
    {
        id: "acc_vvaO3jxQTUsGD6rKoO",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Calle Morelli Nro. 181",
        name: "AGORA SERVICIOS DIGITALES S.A.C.",
        tax_id: "20603150954",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "sara.villanueva@intercorpretail.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-30T19:32:15.799705-03:00",
        updated: "2023-11-30T19:36:02.991133-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_HsyvlXTTka93JFMIMf",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Cal. Morelli Nro. 139",
        name: "INDIGITAL XP S.A.C.",
        tax_id: "20605642731",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "sara.villanueva@intercorpretail.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-30T19:36:51.505666-03:00",
        updated: "2023-11-30T19:37:04.696762-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_HsyvlXTTka93JFMIMf",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Cal. Morelli Nro. 139",
        name: "INDIGITAL XP S.A.C.",
        tax_id: "20605642731",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "sara.villanueva@intercorpretail.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-30T19:36:51.505666-03:00",
        updated: "2023-11-30T19:37:04.696762-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_77TG4FFZ5gf6xUe7tn",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Cal. Morelli Nro. 181",
        name: "INMOBILIARIA MILENIA",
        tax_id: "20499141051",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "sara.villanueva@intercorpretail.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-30T19:28:13.289627-03:00",
        updated: "2023-11-30T19:28:29.086484-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_GvsmUhPS4Gx4zNtxF1",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "Cal. Morelli Nro. 139 Int. 204 (Piso 2)",
        name: "IR MANAGEMENT S.R.L.",
        tax_id: "20492946541",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "sara.villanueva@intercorpretail.pe",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-11-30T19:29:16.364827-03:00",
        updated: "2023-11-30T19:29:16.364854-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_d6fB42dTCY5s3erZHY",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "San Borja",
        name: "OPERADORA DE SERVICIOS LOGISTICOS S.A.C.",
        brand: 'brand--oslo.png',
        tax_id: "20607281921",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:37:39.743826-03:00",
        updated: "2023-09-26T17:37:39.743852-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_QnuP1IN6MfG11j1lHw",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "San Borja",
        name: "Plaza Vea Oriente",
        brand: 'brand--plaza-vea.png',
        tax_id: "20601233488",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:37:03.658409-03:00",
        updated: "2023-09-26T17:37:03.658434-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_32dZ33458mMdCSb2tB",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "San Borja",
        name: "ADMINISTRACIÓN FOOD REGIONAL S.A.C",
        tax_id: "20608297163",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "elvis.velasquez@spsa.pe",
        email_template: "classic",
        logo: null,
        phone: null,
        tax_settings: {},
        website: null,
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-09-26T17:39:30.051927-03:00",
        updated: "2023-09-26T17:39:30.051950-03:00",
        country: "PE",
        state: "PE150130"
    },
    {
        id: "acc_8YXFCHDEJT4Re1exOg",
        base_currency: {
            id: "PEN",
            conversion: null,
            name: "Sol peruano",
            name_en: "Sol peruano",
            default: true,
            is_chargeable: true,
            available: true,
            rounded: false,
            country: "PE"
        },
        me: null,
        address: "La Victoria",
        name: "QUIMICA SUIZA S.A.C",
        brand: 'brand--quimica-suiza.png',
        tax_id: "20100085225",
        color_asset: "#0583AE",
        enable_customers: false,
        early_access_features: false,
        email: "pvela@quimicasuiza.com",
        email_template: "classic",
        logo: null,
        phone: "",
        tax_settings: {},
        website: "",
        template_mode: "standard",
        auto_msg: true,
        status: "active",
        created: "2023-10-05T17:23:03.524329-03:00",
        updated: "2023-10-05T17:23:43.651873-03:00",
        country: "PE",
        state: "PE140106"
    }
]