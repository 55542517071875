const outboundRoutes = [
  {
    path: '/:prefix(test)?/outbound/template/preview/:id',
    name: 'TemplatePreview',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Preview plantillas - outbound'
    },
    component: () => import('@/modules/outbound/templates/views/TemplatePreview.vue')
  },
  {
    path: '/:prefix(test)?/outbound/template/active-campaign/:id',
    name: 'ActiveCampaignList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Plantillas - Campañas activas - outbound'
    },
    component: () => import('@/modules/outbound/templates/views/ActiveCampaignList.vue')
  },
  {
    path: '/:prefix(test)?/outbound/template/statistics-campaign/:id',
    name: 'StatisticsCampaignList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Plantillas - Estadísticas de campaña - outbound'
    },
    component: () => import('@/modules/outbound/templates/views/StatisticsCampaignList.vue')
  },
  {
    path: '/:prefix(test)?/outbound/template/shipping-settings',
    name: 'ShippingsSettingsTemplate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Plantillas - Ajustes de envío - outbound'
    },
    component: () => import('@/modules/outbound/templates/views/ShippingsSettingsTemplate.vue')
  },
  {
    path: '/:prefix(test)?/outbound/template/:id',
    name: 'TemplatePersonalization',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Plantillas - Personalización - outbound'
    },
    component: () => import('@/modules/outbound/templates/views/TemplatePersonalization.vue')
  },
  {
    path: '/:prefix(test)?/outbound/template/edit/:id',
    name: 'EditTemplate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Editar plantillas - outbound'
    },
    component: () => import('@/modules/outbound/templates/components/templates/EditTemplate.vue')
  },
  {
    path: '/:prefix(test)?/outbound/tracker',
    name: 'TrackerMonitor',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TrackerMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Tracker monitor - outbound'
    },
    component: () => import('@/modules/outbound/tracker/views/TrackerMonitor.vue')
  },
  {
    path: '/:prefix(test)?/outbound/template',
    name: 'TemplateList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Plantillas - outbound'
    },
    component: () => import('@/modules/outbound/templates/views/TemplateList.vue')
  },
  {
    path: '/:prefix(test)?/outbound',
    name: 'PanelOutbound',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PanelOutbound',
      requireAuth: true,
      requireAccount: true,
      title: 'Panel de inicio - Outbound'
    },
    component: () => import('@/modules/outbound/PanelOutbound.vue')
  },
  {
    path: '/:prefix(test)?/reports/documents',
    name: 'SentDocumentReportList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentReportList',
      requireAuth: true,
      requireAccount: true,
      isActive: true,
      title: 'Documentos enviados'
    },
    component: () => import('@/modules/outbound/reports/SentDocumentList')
  },
  {
    path: '/:prefix(test)?/outbound/template/monitor',
    name: 'TemplateMonitor',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TemplateMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Monitor de plantillas - outbound'
    },
    component: () => import('@/modules/outbound/templates/views/TemplateMonitor.vue')
  },
  {
    path: '/:prefix(test)?/outbound/tracker/activities',
    name: 'TrackertList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TrackerMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Email tracker - outbound'
    },
    component: () => import('@/modules/outbound/tracker/views/TrackertList.vue')
  },
  {
    path: '/:prefix(test)?/outbound/tracker/:id',
    name: 'TrackerRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'TrackerMonitor',
      requireAuth: true,
      requireAccount: true,
      title: 'Detalle email tracker - outbound'
    },
    component: () => import('@/modules/outbound/tracker/views/TrackerRetrieveUpdate.vue')
  },
  {
    path: '/:prefix(test)?/outbound/portal',
    name: 'PortalList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PortalList',
      requireAuth: true,
      requireAccount: true,
      title: 'Portal consumidor - outbound'
    },
    component: () => import('@/modules/outbound/portal/views/PortalList.vue')
  }
]

export default outboundRoutes
