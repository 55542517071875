import Vue from "vue"
import store from "@/store"
import VueRouter from "vue-router"

// HTTP
import httpRoutes from "@/modules/http/routes"

// AUTH
import authRoutes from "@/modules/auth/routes"

// DOCUMENTS
import documentRoutes from "@/modules/documents/routes"

// ACCOUNT
import accountRoutes from "@/modules/account/routes"

// CUSTOMERS
import customersRoutes from "@/modules/customer/routes"

// APPS
import Applications from "@/modules/apps/views/Main"
import purchaseRoutes from "@/modules/purchase/routes"

// POS
import posRoutes from "@/modules/pos/routes"

// FOLIOS
import foliosRoutes from "@/modules/folios/routes"

// SUPPLIERS
import supplierRoutes from "@/modules/supplier/routes"

// REPORT
import reportRoutes from "@/modules/reports/routes"

// DOWNLOAD CENTER
import downloadCenterRoutes from "@/modules/download-center/routes"

// COMPLIANCE
import complianceRoutes from "@/modules/compliance/routes"

// MAIL TRACKER
import mailTrackerRoutes from "@/modules/mail-tracker/routes"

// PORTAL
import portalRoutes from "@/modules/portal/routes"

// PROCESS
import processRoutes from "@/modules/process/routes"

// HOLDINGS
import holdingRoutes from "../modules/holding/routes"

// OUTBOuND
import outboundRoutes from "../modules/outbound/routes"

import accounts from "@/data/accountsList" // example
// import routesMainByProducts from '@/data/routesMainByProducts' // product

Vue.use(VueRouter)

// const currentProduct = localStorage.getItem('current-product') || 'pos'

// const currentIndex = routesMainByProducts.find((item) => currentProduct === item.product)
// const routesRedirect = [
//   { path: '/', redirect: { name: 'AccountDashboard' } }
// ]

const routes = [
  ...portalRoutes,
  ...processRoutes,
  ...authRoutes,
  ...mailTrackerRoutes,
  ...accountRoutes,
  ...customersRoutes,
  ...supplierRoutes,
  ...purchaseRoutes,
  ...httpRoutes,
  ...documentRoutes,
  ...posRoutes,
  ...foliosRoutes,
  ...reportRoutes,
  ...downloadCenterRoutes,
  ...complianceRoutes,
  ...holdingRoutes,
  ...outboundRoutes,
  // ...routesRedirect,
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "Applications",
      requireAuth: true,
      requireAccount: true,
      title: "Menú de aplicaciones"
    }
  }
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requireAccount) {
    if (store.state.auth.account.id) {
      if (!store.state.auth.userAccount.is_staff) {
        if (store.state.accounts.me?.id === undefined) {
          if (
            to.meta.permissionsRequired !== undefined &&
            !store.state.accounts.me?.is_owner &&
            !to.meta.permissionsRequired?.some((l) =>
              store.state.accounts.me.permissions?.some((v) => v === l)
            )
          ) {
            next({ name: "PermissionDenied" })
          } else {
            next()
          }
        } else {
          if (
            to.meta.permissionsRequired !== undefined &&
            !store.state.accounts.me?.is_owner &&
            !to.meta.permissionsRequired?.some((l) =>
              store.state.accounts.me.permissions?.some((v) => v === l)
            )
          ) {
            next({ name: "PermissionDenied" })
          } else {
            next()
          }
        }
      } else {
        next()
      }
    } else {
      const account = accounts[0]
      store.dispatch("auth/SET_ACCOUNT", { account })
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (store.state.auth.account.id) {
    // track Segment page navigation
    if (process.env.NODE_ENV === "production") {
      window.analytics.page(to.name)
    }
  }
})

router.beforeEach((to, from, next) => {
  const currentClient = "Axteroid"
  document.title = to.meta.title
    ? to.meta.title + ` - ${currentClient}`
    : currentClient
  next(true)
})

export default router
