<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      overlay-color="overlay"
    >
      <v-card class="text-center">
        <v-toolbar class="px-4" color="transparent" flat height="48">
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" class="pt-7 mr-4" justify="center">
              <v-btn @click="dialog=false" icon small><v-icon size="20" color="fontBody">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pb-7">
          <v-img contain :height="220" :src="require(`@/assets/alert-dialog/${env}.svg`)" />
          <span class="d-block subtitle-1 mt-4 font-weight-bold primary--text">Intermitencia en SII</span>
          <v-col class="text-left pb-0">
            Desde el Lunes 10 de julio 2023 el Servicio de Impuestos Internos está presentando demora en la aprobación de los DTEs.
            <!-- Desde el 13 de mayo SUNAT está presentando inconvenientes con el servicio de guías de remisión (GRE), el cual no tiene una hora definida para la corrección. -->
            <span class="d-block mt-5">SII, no tiene una hora definida para la corrección.</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    data () {
      return {
        dialog: false,
        env: process.env?.VUE_APP_MODE ?? 'ax'
      }
    },
    created () {
      this.dialog = true
    }
  }
</script>