const complianceRoutes = [
  {
    path: '/compliance/operation',
    name: 'ComplianceOperation',
    meta: {
      product: 'compliance',
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ComplianceOperation',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Compliance - Panel de operación'
    },
    component: () => import('@/modules/compliance/views/operation/Main')
  },
  {
    path: '/compliance/tasks',
    name: 'ComplianceTask',
    meta: {
      product: 'compliance',
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ComplianceTask',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Task manager - Compliance'
    },
    component: () => import('@/modules/compliance/views/task/Main')
  },
  {
    path: '/compliance/processes',
    name: 'ComplianceProcessList',
    meta: {
      product: 'compliance',
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ComplianceProcessList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Procesos - Compliance'
    },
    component: () => import('@/modules/compliance/views/process/ProcessList')
  },
  {
    path: '/compliance/processes/calendar',
    name: 'ProcessCalendar',
    meta: {
      product: 'compliance',
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ProcessCalendar',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Calendario de procesos - Compliance'
    },
    component: () => import('@/modules/compliance/views/process/ProcessCalendar')
  },
  {
    path: '/compliance/processes/:id',
    name: 'ComplianceProcessDetail',
    meta: {
      product: 'compliance',
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ComplianceProcessList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Proceso - Compliance'
    },
    component: () => import('@/modules/compliance/views/process/ProcessRetrieve')
  },
  {
    path: '/compliance/quadratures/:id/documents',
    name: 'ComplianceDocumentList',
    meta: {
      product: 'compliance',
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ComplianceDocumentList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Documentos - Compliance'
    },
    component: () => import('@/modules/compliance/views/process/DocumentList')
  },
  {
    path: '/compliance/sire',
    name: 'ComplianceSireList',
    meta: {
      product: 'compliance',
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ComplianceSireList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'SIRE - Compliance'
    },
    component: () => import('@/modules/compliance/views/sire/SireList')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return complianceRoutes?.filter(({meta}) => meta?.use?.some((v) => v === env))
}
export default list()