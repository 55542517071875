const accountRoutes = [
  {
    path: "/:prefix(test)?/account/settings/basic",
    name: "AccountBasic",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      requireAccount: true,
      showDetails: true,
      use: ["fc", "ax"],
      title: "Configuración de la organización",
      breadcrumbs: {
        main: "Configuración",
        children: [{ text: "Organización" }]
      }
    },
    component: () => import("@/modules/account/views/Basic")
  },
  {
    path: "/:prefix(test)?/account/settings",
    name: "AccountMain",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountMain",
      requireAuth: true,
      requireAccount: true,
      showDetails: true,
      use: ["fc", "ax"],
      title: "Configuración de la organización"
    },
    component: () => import("@/modules/account/views/Main"),
    children: [
      {
        path: "/:prefix(test)?/account/settings/branding",
        name: "AccountSettingsBranding",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["fc", "ax"],
          title: "Branding"
        },
        component: () => import("@/modules/account/views/Branding")
      },
      {
        path: "/:prefix(test)?/account/settings/notifications",
        name: "AccountNotifications",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["ax"],
          showSupport: true,
          title: "Notificaciones",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Notificaciones" }]
          }
        },
        component: () => import("@/modules/account/views/Notifications")
      },
      {
        path: "/:prefix(test)?/account/settings/documents/irs/:id",
        name: "IrsBiller",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["fc", "ax"],
          title: "Emisión"
        },
        component: () => import("@/modules/account/views/irs/Biller")
      },
      {
        path: "/:prefix(test)?/account/settings/irs/built_in",
        name: "IrsBuiltInSettings",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["fc", "ax"],
          title: "SII",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "SII" }]
          }
        },
        component: () => import("@/modules/account/views/irs/sii/Settings")
      },
      {
        path: "/:prefix(test)?/account/settings/collections",
        name: "AccountSettingsCollections",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["fc", "ax"],
          title: "Cobranzas"
        },
        component: () => import("@/modules/account/views/Collections")
      },
      {
        path: "/:prefix(test)?/account/settings/webhooks",
        name: "AccountSettingsWebhooksListCreate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["fc", "ax"],
          title: "Webhooks",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Developers" }]
          }
        },
        component: () => import("@/modules/account/views/WebhooksListCreate")
      },
      {
        path: "/:prefix(test)?/account/settings/webhooks/:id",
        name: "AccountSettingsWebhooksRetrieveUpdate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          use: ["fc", "ax"],
          title: "Webhooks"
          // breadcrumbs: [
          //   {
          //     text: 'Configuración',
          //     to: { name: 'AccountBasic' },
          //     link: true,
          //     exact: true,
          //     disabled: false
          //   },
          //   {
          //     text: 'Webhooks',
          //     disabled: true
          //   }
          // ]
        },
        component: () =>
          import("@/modules/account/views/WebhooksRetrieveUpdate")
      },
      {
        path: "/:prefix(test)?/account/settings/billing",
        name: "AccountBilling",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          use: ["fc", "ax"],
          title: "Emisión de Axteroid"
        },
        component: () => import("@/modules/account/views/Billing")
      },
      {
        path: "/:prefix(test)?/account/settings/pos",
        name: "AccountSendLogPos",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["fc", "ax"],
          title: "Envio de LOGS",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "POS" }]
          }
        },
        component: () => import("@/modules/account/views/AccountSendLogPos")
      },
      {
        path: "/:prefix(test)?/account/settings/members",
        name: "AccountMembers",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['view_member', 'change_member', 'delete_member', 'view_invitation', 'add_invitation', 'delete_invitation'],
          use: ["fc", "ax"],
          title: "Miembros",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Miembros" }]
          }
        },
        component: () => import("@/modules/account/views/members/Members")
      },
      {
        path: "/:prefix(test)?/account/settings/members/:id/edit",
        name: "AccountMemberUpdate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['change_member'],
          use: ["fc", "ax"],
          parentTitle: "Miembros",
          title: "Actualizar miembro",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Miembros" }]
          }
        },
        component: () =>
          import("@/modules/account/views/members/MembersUpdate")
      },
      {
        path: "/:prefix(test)?/account/settings/groups",
        name: "AccountGroups",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['view_group', 'add_group', 'change_group'],
          use: ["fc", "ax"],
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Grupos" }]
          }
        },
        component: () => import("@/modules/account/views/groups/Groups")
      },
      {
        path: "/:prefix(test)?/account/settings/groups/create",
        name: "AccountGroupsCreate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['add_group'],
          use: ["fc", "ax"],
          title: "Crear Grupo",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Grupos" }]
          }
        },
        component: () =>
          import("@/modules/account/views/groups/GroupsCreateUpdate")
      },
      {
        path: "/:prefix(test)?/account/settings/groups/:id/edit",
        name: "AccountGroupsUpdate",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['change_group', 'delete_group'],
          use: ["fc", "ax"],
          title: "Actualizar Grupo",
          breadcrumbs: {
            main: "Configuración",
            children: [{ text: "Directorio" }, { text1: "Grupos" }]
          }
        },
        component: () =>
          import("@/modules/account/views/groups/GroupsCreateUpdate")
      },
      {
        path: "/:prefix(test)?/account/settings/receipt",
        name: "AccountReceipt",
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: "AccountBasic",
          requireAuth: true,
          requireAccount: true,
          use: ["fc", "ax"],
          title: "Equipo"
        },
        component: () => import("@/modules/account/views/Receipt")
      }
    ]
  },
  {
    path: "/:prefix(test)?/account/import/documents",
    name: "AccountImportDocuments",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      use: ["fc", "ax"],
      title: "Importación de facturas"
      // breadcrumbs: [
      //   {
      //     text: 'Configuración',
      //     to: { name: 'AccountBasic' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Emisión',
      //     to: { name: 'IrsBuiltInSettings' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Importación de facturas',
      //     disabled: true
      //   }
      // ]
    },
    component: () => import("@/modules/account/views/ImportDocuments")
  },
  {
    path: "/:prefix(test)?/account/exports",
    name: "AccountExports",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      use: ["fc", "ax"]
    },
    component: () => import("@/modules/account/views/Exports")
  },
  {
    path: "/:prefix(test)?/account/exports/customers",
    name: "AccountCustomerExport",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      use: ["fc", "ax"]
    },
    component: () => import("@/modules/account/views/exports/CustomerExport")
  },
  {
    path: "/:prefix(test)?/account/exports/documents",
    name: "AccountExportDocuments",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      use: ["fc", "ax"]
    },
    component: () => import("@/modules/account/views/exports/ExportDocuments")
  },
  {
    path: "/:prefix(test)?/account/exports/credit_notes",
    name: "AccountExportCreditNote",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      use: ["fc", "ax"]
    },
    component: () => import("@/modules/account/views/exports/ExportCreditNote")
  },
  {
    path: "/:prefix(test)?/account/imports",
    name: "AccountImports",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      use: ["fc", "ax"]
    },
    component: () => import("@/modules/account/views/Imports")
  },
  {
    path: "/:prefix(test)?/account/imports/customers",
    name: "AccountImportCustomers",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "AccountBasic",
      requireAuth: true,
      use: ["fc", "ax"]
    },
    component: () => import("@/modules/account/views/imports/ImportCustomers")
  },
  {
    path: "/:prefix(test)?/insight",
    name: "AccountDashboard",
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: "Dashboard",
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_document', 'add_document'],
      use: ["ax"],
      title: "Dashboard"
    },
    component: () => import("@/modules/account/views/DashboardAX")
  }
]
export default accountRoutes
