const portalRoutes = [
  {
    path: '/process',
    name: 'ProcessList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'ProcessList',
      requireAuth: true,
      requireAccount: true,
      permissionsRequired: ['view_purchase'],
      title: 'Emisión por lotes'
    },
    component: () => import('@/modules/process/monitor/views/MonitorList')
  }
]
export default portalRoutes