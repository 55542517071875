import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0352CC',
        secondary: '#525F7F',
        success: '#88B437',
        error: '#DD1A1A',
        lightBlue: '#B1C9EF',
        defaultGrey: '#525F7F',
        background: '#F4F6FA',
        // new definition
        lightGrey: '#F4F6FA',
        fontDraw: '#8B94A9',
        fontBody: '#3A435A',
        bgSearch: '#FFFFFF',
        bgPanel: '#FFFFFF',
        greyChip: '#63627C',
        placeholder: '#EEEFF2',
        off: '#568BDD',
        redMedium: '#E20611',
        supportAlert: '#151E34',
        barCompliance: '#FFFFFF',
        blueDarken: '#023A91',
        skeleton: '#EEEFF2',
        yellow: '#E8A803',
        yellowLight: '#F8E4B1',
        redLight: '#F4B8B8',
        greyMedium: '#C5C8D2',
        overlay: '#525F7F',
        secondaryIconDrawer: '#525F7F',
        fontLinkTable: '#3A435A',
        blueGreyLighten: '#C8D2D7',
        fontChip: '#3A435A',
        purpleLight: '#E6E8FC',
        purpleMedium: '#8BAFE8',
        backgroudLight: '#D7E0FC',
        yellowA: '#FEF2CC',
        redA: '#FACECE',
        blackBlue: '#0022B2',
        greenExtraLight: '#DAE8C1',
        black: '#010101'
      },
      dark: {
        primary: '#2466CB',
        secondary: '#E6E8FC',
        error: '#DD1A1A',
        background: '#080C24',
        lightGrey: '#080C24',
        bgSearch: '#151E34',
        bgPanel: '#151E34',
        defaultGrey: '#9FA8DA',
        greyChip: '#FFFFFF',
        off: '#9FA8DA',
        barCompliance: '#151E34',
        yellowLight: '#FFF4DB',
        redLight: '#F4B8B8',
        lightBlue: '#B1C9EF',
        greyMedium: '#EEEFF2',
        blueGreyLighten: '#C8D2D7',
        fontLinkTable: '#E6E8FC',
        secondaryIconDrawer: '#9FA8DA',
        fontChip: '#3A435A',
        purpleLight: '#E6E8FC',
        blackBlue: '#0352CC',
        greenExtraLight: '#DAE8C1',
        black: '#FFFFFF'
      }
    }
  }
})
