export default [
  {
    id: "insight",
    description: "Análisis y métricas clave para monitorear tu operación",
    icon: "insight",
    name: "Insight",
    disabled: false,
    route: "AccountDashboard",
    dropdownModeTest: false,
    show: false,
    configurations: {
      isActive: false,
      routesList: ["IrsBuiltInSettings", "AccountNotifications"]
    }
  },
  {
    id: "documents",
    description: "Gestiona la emisión de tus documentos",
    icon: "documental",
    name: "Emisión",
    disabled: false,
    route: "DocumentsDashboard",
    dropdownModeTest: false,
    show: true,
    configurations: {
      isActive: true,
      routesList: ["IrsBuiltInSettings", "AccountNotifications"]
    }
  },
  {
    id: "purchase",
    description: "Gestiona la recepción de tus documentos",
    icon: "purchase",
    name: "Recepción",
    disabled: false,
    route: "PurchaseDashboard",
    dropdownModeTest: false,
    show: true,
    configurations: {
      isActive: false,
      routesList: ["IrsBuiltInSettings", "AccountNotifications"]
    }
  },
  {
    id: "folios",
    description: "Administra el número único asociado a tus DTE´s",
    icon: "folios",
    name: "Folios",
    disabled: false,
    route: "FoliosMonitor",
    dropdownModeTest: false,
    show: true,
    configurations: {
      isActive: false,
      routesList: []
    }
  },
  {
    id: "pos",
    description: "Monitorea el parque de puntos de venta",
    icon: "pos",
    name: "PoS",
    disabled: false,
    route: "MonitorList",
    dropdownModeTest: false,
    show: true,
    configurations: {
      isActive: true,
      routesList: ["AccountSendLogPos"]
    }
  },
  {
    id: "compliance",
    description: "Supervisa y gestiona el cumplimiento tributario",
    icon: "compliance",
    name: "Compliance",
    disabled: false,
    route: "ComplianceOperation",
    dropdownModeTest: true,
    show: true,
    configurations: {
      isActive: false,
      routesList: []
    }
  },
  {
    id: "outbound",
    description: "Gestiona la comunicación saliente",
    icon: "outbound",
    name: "Outbound",
    disabled: false,
    dropdownModeTest: false,
    show: true,
    configurations: {
      isActive: false,
      routesList: []
    },
    route: "PanelOutbound"
  },
  // {
  //   id: "shipmate",
  //   description: "Documentación auto asistida para transportistas",
  //   icon: "shipmate",
  //   name: "Shipmate",
  //   disabled: true,
  //   dropdownModeTest: false,
  //   configurations: {
  //     isActive: false,
  //     routesList: []
  //   }
  //   route: 'MainCompliance'
  // },
  {
    id: "bussines",
    description: "Servicios de apoyo a tu organización",
    icon: "bussines",
    name: "Business Services",
    disabled: true,
    dropdownModeTest: false,
    show: true,
    configurations: {
      isActive: false,
      routesList: []
    }
    // route: 'MainCompliance'
  },
  {
    id: "holdings",
    description: "Supervisa tus organizaciones de forma agregada",
    icon: "holdings",
    name: "Holdings",
    disabled: false,
    dropdownModeTest: true,
    show: true,
    configurations: {
      isActive: false,
      routesList: []
    },
    route: "OrganizationList"
  }
]
