<template>
  <v-btn :id="`it-dialog-${actionText.toLowerCase()}`" v-on="on" @click="$emit('click', this)" :disabled="disabled" :outlined="!color" color="primary" :dark="Boolean(color)">
    <v-icon v-if="icon && !icon.right" v-bind="icon" v-text="icon.text" />
    {{actionText}}
    <v-icon v-if="icon && icon.right" v-bind="icon" v-text="icon.text" />
  </v-btn>
</template>
<script>
export default {
  props: {
    attrs: Object,
    text: [String, Function],
    disabled: Boolean,
    icon: Object,
    color: String,
    on: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    actionText () {
      return typeof this.text === 'function' ? this.text() : this.text
    }
  }
}
</script>