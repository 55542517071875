const PosRoutes = [
  {
    path: '/:prefix(test)?/monitor',
    name: 'MonitorList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'MonitorList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Monitor'
    },
    component: () => import('@/modules/pos/monitor/views/MonitorList.vue')
  },
  {
    path: '/:prefix(test)?/monitor/map',
    name: 'MonitorMapDetail',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'MonitorList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Detalle de Monitor'
    },
    component: () => import('@/modules/pos/monitor/views/MonitorMapDetail.vue')
  },
  {
    path: '/:prefix(test)?/rollout',
    name: 'RolloutList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'RolloutList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Tiendas'
    },
    component: () => import('@/modules/pos/rollout/views/RolloutList.vue')
  },
  {
    path: '/:prefix(test)?/branches/report',
    name: 'BranchReportList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchReportList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Reporte de tiendas'
    },
    component: () => import('@/modules/pos/reports/views/BranchReportList')
  },
  {
    path: '/:prefix(test)?/pos/report/:id',
    name: 'PosReportDetail',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchReportList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Detalle del Reporte de POS'
    },
    component: () => import('@/modules/pos/reports/views/PosReportDetail')
  },
  {
    path: '/:prefix(test)?/folios/report/:id',
    name: 'FoliosReportDetail',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchReportList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Detalle del reporte de folios'
    },
    component: () => import('@/modules/pos/reports/views/FoliosReportDetail')
  },
  {
    path: '/:prefix(test)?/folios/report',
    name: 'FoliosReportList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchReportList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Reporte de folios'
    },
    component: () => import('@/modules/pos/reports/views/FoliosReportList')
  },
  {
    path: '/:prefix(test)?/branches',
    name: 'BranchListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Tiendas'
    },
    component: () => import('@/modules/pos/branch/views/BranchListCreate')
  },
  {
    path: '/:prefix(test)?/branches/:id',
    name: 'BranchRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información de la tienda'
    },
    props: true,
    component: () => import('@/modules/pos/branch/views/BranchRetrieveUpdate')
  },
  {
    path: '/:prefix(test)?/branches/activity',
    name: 'BranchesActivityList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchListCreate',
      requireAuth: true,
      requireAccount: true,
      // use: ['ax'],
      title: 'Actividad de tiendas'
    },
    component: () => import('@/modules/pos/branch/views/BranchesActivityList')
  },
  {
    path: '/:prefix(test)?/pos/report',
    name: 'PosBranchReportList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchReportList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Reporte de tiendas'
    },
    component: () => import('@/modules/pos/reports/views/PosBranchReportList')
  },
  {
    path: '/:prefix(test)?/pos/report/:id',
    name: 'PosReportList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'BranchReportList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Reporte de Puntos de ventas por DTE'
    },
    component: () => import('@/modules/pos/reports/views/PosReportList')
  },
  {
    path: '/:prefix(test)?/pos/groups',
    name: 'PosGroupsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosGroupsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Grupos - Puntos de venta'
    },
    component: () => import('@/modules/pos/groups/views/PosGroupsList')
  },
  {
    path: '/:prefix(test)?/pos/groups/:id',
    name: 'PosGroupsRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosGroupsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información del grupo - punto de venta'
    },
    component: () => import('@/modules/pos/groups/views/PosGroupsRetrieveUpdate')
  },
  {
    path: '/:prefix(test)?/pos',
    name: 'PosListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Puntos de venta'
    },
    component: () => import('@/modules/pos/pos/views/PosListCreate')
  },
  {
    path: '/:prefix(test)?/pos/:id',
    name: 'PosRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información del punto de venta'
    },
    component: () => import('@/modules/pos/pos/views/PosRetrieveUpdate')
  },
  {
    path: '/:prefix(test)?/logs',
    name: 'LogsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'LogsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      title: 'Logs'
    },
    component: () => import('@/modules/pos/logs/views/LogsList')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return PosRoutes?.filter(({meta}) => meta?.use?.some((v) => v === env))
}
export default list()