const complianceRoutes = [
  {
    path: '/mail-tracker',
    name: 'MailTracker',
    meta: {
      displayAppBar: false,
      displayNavigationDrawer: false,
      displayDrawer: false,
      displayOrganization: true,
      parent: 'MailTracker',
      requireAuth: true,
      use: ['ax'],
      requireAccount: true,
      title: 'Compras'
    },
    component: () => import('@/modules/mail-tracker/views/MailTracker')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return complianceRoutes?.filter(({meta}) => meta?.use?.some((v) => v === env))
}
export default list()