// import store from '@/store'
const DocumentsRoutes = [
  {
    path: '/:prefix(test)?/documents/create',
    name: 'DocumentsCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      // permissionsRequired: ['add_document'],
      title: 'Nuevo documento'
    },
    component: () => import('@/modules/documents/views/DocumentsCreate')
  },
  {
    path: '/:prefix(test)?/documents',
    name: 'DocumentsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_document', 'add_document'],
      use: ['fc', 'ax'],
      title: 'Documentos'
    },
    component: () => import('@/modules/documents/views/DocumentsList')
  },
  {
    path: '/:prefix(test)?/documents/:id',
    name: 'DocumentsRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información de documento'
    },
    component: () => import('@/modules/documents/views/Main')
  },
  {
    path: '/:prefix(test)?/documents/:id/create',
    name: 'DocumentsClone',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Clonar documento'
    },
    component: () => import('@/modules/documents/views/DocumentsCreate')
  },
  {
    path: '/:prefix(test)?/batch/documents',
    name: 'DocumentsBatchListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax', 'fc'],
      // permissionsRequired: ['view_batch', 'add_batch'],
      title: 'Facturación por lotes'
    },
    component: () => import('@/modules/documents/batch/views/DocumentsBatchListCreate')
  },
  {
    path: '/:prefix(test)?/batch/documents/:id',
    name: 'DocumentsBatchRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['ax', 'fc'],
      // permissionsRequired: ['view_batch'],
      title: 'Detalles de facturación por lotes',
      breadcrumbs: [
        {
          text: 'Facturación',
          to: { name: 'DocumentsBatchListCreate' },
          link: true,
          exact: true,
          disabled: false
        },
        {
          text: 'Facturación por lotes',
          disabled: true
        }
      ]
    },
    component: () => import('@/modules/documents/batch/views/DocumentsBatchRetrieve')
  },
  {
    path: '/:prefix(test)?/credit-note/:id',
    name: 'CreditNoteRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Nota de crédito'
    },
    component: () => import('@/modules/documents/views/CreditNoteRetrieve')
  },
  {
    path: '/:prefix(test)?/debit-notes/:id',
    name: 'DebitNoteRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Nota de débito'
    },
    component: () => import('@/modules/documents/views/DebitNoteRetrieve')
  },
  {
    path: '/:prefix(test)?/document/dashboard',
    name: 'DocumentsDashboard',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'Dashboard',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_document', 'add_document'],
      use: ['ax'],
      title: 'Resumen Gestión documental'
    },
    component: () => import('@/modules/documents/Dashboard')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return DocumentsRoutes?.filter(({meta}) => meta?.use?.some((v) => v === env))
}
export default list()