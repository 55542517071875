import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  props: {
    showPagination: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    showCreate: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    showCriteria: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    showStatus: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    hasLink: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    showPermissionDenied: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    GenericViewMixin
  ],
  data () {
    return {
      page: parseInt((this.$route.query || {}).page || 1),
      generalQueryList: ['ordering', 'page'],
      query: {},
      pagination: 100,
      selectedRows: [],
      dialogCreate: false,
      isClosePanel: false
    }
  },
  computed: {
    showAll () {
      return Object.keys(this.showCriteria).length
    },
    totalPages () {
      return this.count > this.pagination ? Math.ceil(this.count / this.pagination) : 1
    },
    activeGetList () {
     return Object.keys(this.$route.query).find(item => this.generalQueryList.includes(item))
    },
    emptyStateFilter () {
      return (filters) => Object.keys(this.$route.query).some((filter) => filters?.find((item) => filter.search(item.id)) ?? false)
    },
    currentParams () {
      let params = JSON.parse(JSON.stringify(this.$route.params))
      delete params.id
      return params
    }
  },
  watch: {
    $route: {
      handler (val) {
        if (typeof this.getList === 'function') {
          this.getList()
        }
      },
      inmediate: true,
      deep: true
    },
    page (val) {
      this.setPage()
      if (['AccountMembers', 'DocumentsBatchRetrieve'].includes(this.$route.name)) this.$emit('isActive', val)
    }
  },
  // created () {
  //   if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length) || !['AccountGroups', 'LogsList', 'DocumentSummary', 'SentDocumentList', 'DownloadCenterList'].includes(this.$route.name)) {
  //     this.getList()
  //   }
  // },
  methods: {
    latestRecords (sort = '-date') {
      this.$router.replace({name: this.$route.name, params: this.currentParams, query: { ordering: `${sort},-created`} }).catch(() => {})
    },
    setQuery () {
      this.$router.replace({name: this.$route.name, query: this.query}).catch(() => {})
    },
    setPage () {
      if (this.$route.query.page === this.page) {
        return false
      }
      this.$router.replace({name: this.$route.name, query: Object.assign({}, this.$route.query, {page: this.page})})
    },
    setSort (value) {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      let val = null
      if (query.ordering === value && query.ordering?.search(/-/) === -1) {
        if (query.ordering === 'prefix') delete query.ordering
        val = `-${value}`
      }

      if (this.$route.name === 'DocumentsList') {
        let auxQuery = typeof query.ordering === 'object' ? query.ordering[0] : query.ordering
        if (value !== 'created') val = auxQuery === value && auxQuery?.search(/-/) === -1 ? [`-${value}`, '-created'] : [value, 'created']
        else if (auxQuery === value && auxQuery?.search(/-/) === -1) val = `-${value}`
        else val = value
      }
      this.$router.replace({name: this.$route.name, params: this.currentParams, query: Object.assign({}, query, {ordering: val})}).catch(err => err)
    }
  }
}
